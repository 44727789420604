<div class="section primary">
  <div class="content__container clearfix">
    <div class="col-xs-12 col-sm-10 col-sm-offset-1 inner-block">
      <h2>Kom igång med WOO</h2>
      <p class="padding-top-bottom--2x">
        Skriv in dina uppgifter här så tar vi kontakt med dig inom några dagar
        och berättar mer om hur du kommer igång med din första kampanj!
      </p>
      <div class="section padding--3x border-radius margin-bottom--3x">
        <div class="animate" [ngClass]="{faded: formSubmitSuccess}">
          <form ngNativeValidate (ngSubmit)="submitSignupForm()">
            <div class="clearfix">
              <div class="col-xs-12 col-sm-6">
                <div class="input__container">
                  <label for="firstName">Förnamn</label>
                  <input type="text" id="firstName" name="firstName" [(ngModel)]="formSignup.first_name" required>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6">
                <div class="input__container">
                  <label for="lastName">Efternamn</label>
                  <input type="text" id="lastName"  name="lastName" [(ngModel)]="formSignup.last_name" required>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6">
                <div class="input__container">
                  <label for="email">Min e-post</label>
                  <input type="email" id="email" name="email" [(ngModel)]="formSignup.email" required>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6">
                <div class="input__container">
                  <label for="company">Jag jobbar på</label>
                  <input type="text" id="company" name="company" [(ngModel)]="formSignup.company" required>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6">
                <div class="input__container">
                  <span class="input__optional__text">Frivillig</span>
                  <label for="phone">Telefonnummer</label>
                  <input type="text" id="phone" name="phone" [(ngModel)]="formSignup.phone">
                </div>
              </div>
              <div class="col-xs-12 margin-bottom--2x">
                <div>
                  Läs mer om vår <a [href]="fileService.getUrlToFile(FileType.integrityPolicy)" target="_blank">Integritetspolicy</a>.
                </div>
              </div>
              <div class="col-xs-12">
                <button class="button">Skicka</button>
              </div>
            </div>
          </form>
        </div>
        <div class="success__text animate text-center" [ngClass]="{faded: !formSubmitSuccess}">
          <h1>Wooho!</h1>
          <h4>Det där gick ju bra. Vi hör av oss så snart vi kan!</h4>
        </div>
      </div>
    </div>
  </div>
</div>
