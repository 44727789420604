<div>
  <h3>Fördelning per publicistgrupp i WOO-RBS-kampanjer</h3>
  <div *ngIf="loaded.publisherGroupSettings">
    <p>Observera att fördelningen per publicistgrupp inte nödvändigtvis behöver stämma med det faktiska utfallet.</p>
    <form
      (ngSubmit)="updatePublisherGroups()">

      <h4>WOO RBS nationell</h4>
      <div *ngFor="let publisherGroup of publisherGroups; let index = index"
        class="relative display--inline-block width--30 margin-right--2x margin-bottom--2x">
        <div class="input__container">
          <label>{{publisherGroup.name}}</label>
          <input type="number" id="woo_rbs_share{{index}}" name=woo_rbs_share{{index}} [(ngModel)]="publisherGroup.woo_rbs_share" min="0" max="100" step="0.1">
        </div>
      </div>

      <div *ngIf="loaded.systemServiceSettings">
        <h4>WOO RBS regional</h4>
        <div>
          <input type="checkbox" class="checkbox" name="enable_woo_local_rbs_share"
            [checked]="settings.enable_woo_local_rbs_share"
            [(ngModel)]="settings.enable_woo_local_rbs_share">
          <label for="enable_woo_local_rbs_share" class="checkbox">Aktiverad</label>
        </div>
        <div *ngFor="let publisherGroup of publisherGroups; let index = index"
          class="relative display--inline-block width--30 margin-right--2x margin-bottom--2x">
          <div class="input__container">
            <label>{{publisherGroup.name}}</label>
            <input type="number" id="woo_local_rbs_share{{index}}" name=woo_local_rbs_share{{index}} [(ngModel)]="publisherGroup.woo_local_rbs_share" min="0" max="100" step="0.1"
              [disabled]="!settings.enable_woo_local_rbs_share">
          </div>
        </div>
      </div>

      <div *ngIf="loaded.systemServiceSettings">
        <h4>WOO RBS målgrupp</h4>
        <div>
          <input class="checkbox" type="checkbox" name="enable_woo_target_group_rbs_share"
             [checked]="settings.enable_woo_target_group_rbs_share" [(ngModel)]="settings.enable_woo_target_group_rbs_share">
          <label for="enable_woo_target_group_rbs_share" class="checkbox">Aktiverad</label>
        </div>
        <div *ngFor="let publisherGroup of publisherGroups; let index = index"
          class="relative display--inline-block width--30 margin-right--2x margin-bottom--2x">
          <div class="input__container">
            <label>{{publisherGroup.name}}</label>
            <input type="number" id="woo_target_group_rbs_share{{index}}" name=woo_target_group_rbs_share{{index}} [(ngModel)]="publisherGroup.woo_target_group_rbs_share" min="0" max="100" step="0.1"
              [disabled]="!settings.enable_woo_target_group_rbs_share">
          </div>
        </div>
        <div>
        </div>

        <button type="submit" class="button margin-top--3x" value="Spara">Spara</button>
      </div>

    </form>
  </div>
  <h3>Utfall WOO RBS nationell (förra månaden)</h3>
  <dot-loader [visibility]="!loaded.publisherOutcomes" description="Hämtar statistik"></dot-loader>
  <div *ngIf="loaded.publisherOutcomes" class="row">
    <div class="col-xs-6 col-sm-6">
      <table class="table table--woo table--info">
        <thead>
          <tr>
            <th>Publicist</th>
            <th>Impressions</th>
            <th>Andel</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let publisher of publisherOutcomes">
            <td>{{publisher.name}}</td>
            <td>{{publisher.impressions | number}}</td>
            <td>{{publisher.impressions/publisherOutcomeTotal | percent: '1.0-2'}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
