<div>
  <div class="tabbable__panel clearfix shadow-z-1" #headerPos>
    <div class="flex__container--seperate padding--2x">
      <h3>Här kan du administrera tillgängliga systemmeddelanden</h3>
      <button class="button info small float--right margin-top--1x" (click)="showNewSystemMessage(true)">Nytt meddelande</button>
    </div>
  </div>
  
  <div class="inner-block relative">
    
    <div id="lists" class="row">
      <div class="col-xs-12">
        <div class="tabbable__panel clearfix shadow-z-1">
          <div class="col-xs-12">
            <h3>Aktiva meddelanden</h3>
            <p class="text--black margin-top-bottom--2x">Kom ihåg att ta bort meddelanden med jämna mellanrum då alla aktiva meddelanden visas för nya Woo-användare!</p>
          </div>
        </div>
        <table class="table table--woo table--info table--hover table--align--middle table--min-width table--system--messages">
          <thead>
            <tr>
              <th>Skapad</th>
              <th>Meddelande</th>
              <th>Typ</th>
              <th>Roller</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let message of systemMessages; trackBy: trackById">
              <td>
                {{message.created_at | date}}
              </td>
              <td>
                {{message.message}}
              </td>
              <td>
                <span *ngIf="message.type === 'info'">Information</span>
                <span *ngIf="message.type === 'warning'">Varning</span>
              </td>
              <td *ngIf="message.roles.length === 0">
                <span>*</span>
              </td>
              <td *ngIf="message.roles.length !== 0">
                <div *ngFor="let role of message.roles">
                    <span>{{roleName(role)}}</span>
                </div>
              </td>
              <td>
                <button (click)="removeMessage(message)" class="button danger margin--none">
                  <span class="icon-trash margin-right--1x"></span> Radera
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row margin-top--3x" #newSystemMessage>
      <div class="col-xs-12">
        <div id="newItemMessage" class="account__card away" [ngClass]="{away: (!show.createMessageForm)}">
          <div *ngIf="show.createMessageForm">
            <h3>Skapa nytt meddelande</h3>
            <form (ngSubmit)="addMessage(newMessage)">
              <div class="input__container">
                <label for="text">Meddelande</label>
                <textarea id="text" type="text" name="text" [(ngModel)]="newMessage.message" required></textarea>
              </div>
              <div class="select__container">
                <label for="messageType">Typ</label>
                <select id="messageType" name="messageType" [(ngModel)]="newMessage.type" required>
                  <option value='info'>Information</option>
                  <option value='warning'>Varning</option>
                </select>
              </div>
              <div>
                <button type="button" class="link pull-left" (click)="selectAllRoles()">Välja Alla</button>
                <button type="button" class="link" (click)="deselectAllRoles()">Välj bort Alla</button>
              </div>
              <div *ngFor="let role of bookingRoles(); trackBy: trackRole" class="input__container col-xs-4">
                <input type="checkbox" class="checkbox" name="role.userRole" [(ngModel)]="role.selected">
                <label class="checkbox" for="role.userRole">{{role.label}}</label>
              </div>
              <div class="clearfix"></div>
              <div class="divider medium margin-top-bottom--2x"></div>
              <div *ngFor="let role of noneBookingRoles(); trackBy: trackRole" class="input__container col-xs-4">
                <input type="checkbox" name="role.userRole" class="checkbox" [(ngModel)]="role.selected">
                <label for="role.userRole" class="checkbox">{{role.label}}</label>
              </div>
              <div class="clearfix"></div>
              <div>
                <button type="submit" class="button pull-left" [disabled]="disableSubmit(newMessage)">Skapa</button>
                <button type="button" class="link" (click)="cancelNewMessage() && showNewSystemMessage(true, headerPos)">Avbryt</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
