import { Component, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { valueAccessorProvider } from '../../utils/provider-builders';

const template = /* html */ `
  <div class="display--flex align-items--center">
    <div *ngIf="offLabel" class="toggle__container">
      <h4 class="margin--none animate" [ngClass]="{'text-muted': value}">{{offLabel}}</h4>
    </div>
    <div class="toggle__container" [ngClass]="{'margin--none': !offLabel && !onLabel}">
      <input
        type="checkbox"
        id="{{id}}"
        name="{{name}}"
        class="cbx hidden"
        (change)="onToggle($event)"
        [checked]="value"
        [disabled]="disabled"
        [attr.e2e-id]="name">
      <label class="lbl center" for="{{id}}">
        <span class="step-icon toggle-icon" [ngClass]="{'icon-check': value, 'icon-close-x': !value}"></span>
      </label>
    </div>
    <div  *ngIf="onLabel" class="toggle__container">
      <h4 class="margin--none animate" [ngClass]="{'text-muted': !value}">{{onLabel}}</h4>
    </div>
  </div>
      `;

@Component({
  selector: 'toggle',
  template: template,
  providers: [valueAccessorProvider(Toggle)],
})
export class Toggle implements ControlValueAccessor {
  @Input() name: string;
  @Input() offLabel: string;
  @Input() onLabel: string;
  id: string;
  value: boolean;

  static idCounter = 0;
  constructor() {
    this.id = 'toggle' + Toggle.idCounter++;
  }

  onChange: (_: any) => void;
  onTouched: () => void;
  disabled: boolean;

  onToggle(event: any): void {
    const newValue: boolean = event.target.checked;
    if (newValue !== this.value) {
      this.value = newValue;
      this.onChange(newValue);
    }
    this.onTouched();
  }

  writeValue(value: boolean): void {
    this.value = value ? value : false;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
