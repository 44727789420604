<div #initialFocusTarget tabindex="0"></div>
<div class="modal fade" role="dialog" #bootstrapModal (click)="$event.stopPropagation()">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button *ngIf="!blocking" type="button" class="close" data-dismiss="modal">
          <span class="icon-close-x" ></span>
        </button>
      </div>
      <div class="modal-body">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
