import { Component, Injectable, OnInit } from '@angular/core';
import { EnvironmentService } from '../../woo_services.module/EnvironmentService';
import { EosService } from '../../woo_services.module/EosService';

@Injectable()
@Component({
  selector: 'eos',
  templateUrl: './eos.component.html',
  styles: ['.margin-right--4px { margin-right: 4px; }'],
})
export class Eos implements OnInit {
  tab = 0;
  readonly apiBaseUrl = this.env.apiUrl;

  constructor(private eosService: EosService, private env: EnvironmentService) {}

  async ngOnInit(): Promise<void> {
    console.debug(await this.eosService.getRegions());
    console.debug(await this.eosService.getPublishers());
    console.debug(await this.eosService.getProducts());
  }

  setTab(tab: number): void {
    this.tab = tab;
  }
}
