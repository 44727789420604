<table class="table table-striped small">
    <thead>
        <tr>
            <th>År/månad</th>
            <th>Nettoutfall</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let month of aggregatedRows.months">
            <td>{{aggregatedRows.year}}-{{month.month | number:'2.0-0'}}</td>
            <td>{{month.net_estimate | currency:'SEK'}}</td>
        </tr>
        <tr>
          <td>Totalt {{aggregatedRows.year}}</td>
          <td>{{aggregatedRows.net_estimate | currency:'SEK'}}</td>
        </tr>
    </tbody>
</table>
