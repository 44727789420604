<div class="account-card block">
  <h1>Mitt konto</h1>
  <div class="account__card shadow-z-1">
    <button class="button secondary pull-right" (click)="logout()">Logga ut</button>
    <h3>{{currentUser.first_name}} {{currentUser.last_name}}</h3>
    <h4>{{currentUser.email}}</h4>
  </div>

  <div class="account__card shadow-z-1">
    <div>
      <h3>Uppdatera lösenord</h3>
      <update-password-form [current]="true"></update-password-form>
    </div>
  </div>
</div>
