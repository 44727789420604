import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { sortBy } from 'lodash-es';
import { timer } from 'rxjs';
import { MIN_DATE } from '../../../utils/date';
import { scrollTo } from '../../../utils/dom';
import { InitialDate } from '../../../woo_components.module/date_selectors/shared/types';
import { AgencyService, AgencyStatistics, DateType } from '../../../woo_services.module/AgencyService';
import { ChartService } from '../../../woo_services.module/ChartService';
import { DialogService } from '../../../woo_services.module/DialogService';
import { CompactAgency, CompactCustomer } from '../../../woo_services.module/shared-types';

@Component({
  selector: 'agency-overview',
  templateUrl: './agency-overview.component.html',
})
export class AgencyOverview implements OnInit {
  @ViewChild('overviewSection', { static: true }) overviewSection: ElementRef;

  readonly ChartUnit = ChartUnit;
  readonly DateType = DateType;
  readonly MIN_DATE = MIN_DATE;
  readonly InitialDate = InitialDate;

  readonly request = {
    from: null,
    to: null,
    agency: null as CompactAgency & { customers: CompactCustomer[] },
    customer: null as CompactCustomer,
    dateType: DateType.StartDate,
  };

  agencies = [] as CompactAgency[];
  statistics: AgencyStatistics;

  readonly doughnutOptions = this.chartService.doughnutChart.options;
  readonly doughnutColors: string[] = this.chartService.tv4Colors;

  pieChartUnit = ChartUnit.Budget;

  readonly bookingStats = {
    budgets: [] as number[],
    counts: [] as number[],
    types: [] as string[],
  };

  private _column = 'name';

  set column(value: string) {
    this._column = value;
    this.updateSortedCampaigns();
  }

  get column(): string {
    return this._column;
  }

  private _reverse = false;

  set reverse(value: boolean) {
    this._reverse = value;
    this.updateSortedCampaigns();
  }

  get reverse(): boolean {
    return this._reverse;
  }

  bookingTopList = [];
  sortedCampaigns = [];

  hasLoaded = {
    agencies: false,
  };

  private readonly typesDictionary = {
    client: 'Direktkund',
    planner: 'RP',
    agency: 'Byrå',
    unknown: '',
  };

  show = {
    overviewSection: false,
  };

  constructor(
    private agencyService: AgencyService,
    private dialogService: DialogService,
    private chartService: ChartService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.agencies = await this.agencyService.getAgencies();
    this.hasLoaded.agencies = true;
  }

  async getData(request: Record<string, any>): Promise<void> {
    if (!(this.request.from && this.request.to)) {
      this.dialogService.openError('Vänligen fyll i datum för att kunna se data.');
    } else {
      const progressCallback = this.dialogService.openBlockingWithProgress('Hämtar data');
      const agencyId = (request.agency && request.agency.id) || null;
      const customerId = (request.customer && request.customer.id) || null;

      this.statistics = await this.agencyService.getAgencyOverview(
        agencyId,
        this.request.from,
        this.request.to,
        customerId,
        request.dateType,
        progressCallback,
      );
      this.dialogService.closeBlocking();
      if (!this.statistics.campaigns.length) {
        this.dialogService.openError('Åh nej! Det finns inga kampanjer för den period hos byrån du angav.');
      } else {
        this.translateCampaigns();
        this.setPieData();
        this.show.overviewSection = true;
        timer(200).subscribe(() => scrollTo(this.overviewSection.nativeElement)); // Does not work well with old dialogs
      }

      this.updateBookingTopList();
      this.updateSortedCampaigns();
    }
  }

  private updateBookingTopList() {
    this.bookingTopList = sortBy(this.statistics.booking_statistics, 'budget').reverse().slice(0, 5);
  }

  private updateSortedCampaigns() {
    this.sortedCampaigns = sortBy(this.statistics.campaigns, this.column);
    if (this.reverse) this.sortedCampaigns.reverse();
  }

  togglePieChartUnit(): void {
    this.pieChartUnit = this.pieChartUnit === ChartUnit.Count ? ChartUnit.Budget : ChartUnit.Count;
  }

  setPieData(): void {
    const bookingRoles = [] as BookingRole[];

    this.statistics.booking_statistics.forEach((person) => {
      person.type = this.translateTypes(person.type);
      const bookingRole = bookingRoles.find((r) => r.type === person.type);
      if (bookingRole === undefined) {
        bookingRoles.push({ type: person.type, count: person.count, budget: person.budget });
      } else {
        bookingRole.count += person.count;
        bookingRole.budget += person.budget;
      }
    });
    this.bookingStats.budgets = bookingRoles.map((role) => role.budget);
    this.bookingStats.counts = bookingRoles.map((role) => role.count);
    this.bookingStats.types = bookingRoles.map((role) => role.type);
  }

  formatNameAndType(name: string, type: any): string {
    if (name && type) {
      return `${type} (${name})`;
    } else if (!type) {
      return name;
    } else {
      return '-';
    }
  }

  agencyChanged = async (newValue: CompactAgency): Promise<void> => {
    if (newValue?.id) {
      this.request.agency.customers = await this.agencyService.getAllAgencyCustomers(newValue.id);
    }
    this.request.customer = null;
  };

  translateCampaigns(): void {
    this.statistics.campaigns.forEach((campaign) => {
      campaign.booked_by.type = this.translateTypes(campaign.booked_by.type);
    });
  }

  translateTypes(type: string): string {
    return this.typesDictionary[type] !== undefined ? this.typesDictionary[type] : type;
  }

  periodChanged = (event: { start: Date; end: Date }): void => {
    this.request.from = event.start;
    this.request.to = event.end;
  };
}

interface BookingRole {
  type: string;
  count: number;
  budget: number;
}

enum ChartUnit {
  Count = 'st',
  Budget = 'kr',
}
